<template>
  <el-table
    :height="height"
    ref="table"
    :class="$options.name"
    :data="data"
    v-loading="loading"
    :empty-text="loading ? ' ' : '暂无数据'"
    :border="border"
    :stripe="stripe"
    :span-method="spanMethod"
    :size="size"
    :row-style="rowStyle"
    :rowKey="rowKey"
    :row-class-name="rowClassName"
    v-on="$listeners"
  >
    <template v-if="isSelection">
      <slot name="column-selection-prepend" />
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :selectable="selectable"
      ></el-table-column>
      <slot name="column-selection-append" />
    </template>
    <slot name="column-prepend" />
    <template v-if="indexNumber">
      <slot name="column-index-prepend" />
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <slot name="column-index-append" />
    </template>
    <template v-for="(column, i) in _columns">
      <slot :name="`column-${i}`"> </slot>
      <el-table-column
        :key="i"
        :class-name="column.main ? 'table-column-main' : ''"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :min-width="column['min-width']"
        :header-align="column['header-align']"
        :align="column.align"
        :fixed="column.fixed"
        :sortable="column.sortable"
        :formatter="column.formatter"
      />
    </template>
    <!-- 后续统一用这个 -->
    <slot name="column-append" />
    <!-- 旧的版本后面改下 -->
    <slot />
  </el-table>
</template>
<script>
import TableProps from './table-props'
export default {
  name: 'base-table',
  mixins: [TableProps],
  data () {
    return {
      tableLoading: false
    }
  },
  props: {
    height: {
      type: String,
      default: '100%'
    },
    /**
     * 表格绑定数据
     */
    data: {
      type: Array,
      default: () => []
    },
    isSelection: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    stripe: {
      type: Boolean,
      default: true
    },
    indexNumber: {
      type: Boolean,
      default: false
    },
    rowStyle: {
      type: [Function, Object],
      default: () => ({})
    },
    rowKey: {
      type: [Function, String],
      default: ''
    },
    // 格式化方法，优先匹配Label后缀
    formatter: {
      type: Function,
      default: (row, column, value, index) => {
        return (
          row[`${column.property}Label`] ||
          row[`${column.property}__LABEL`] ||
          value
        )
      }
    },
    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className
    rowClassName: [String, Function]
  },
  computed: {
    _columns () {
      return this.columns.map((col) => {
        return {
          ...col,
          formatter: col.formatter || this.formatter
        }
      })
    },
    doLayout () {
      return this.$refs.table.doLayout
    },
    $table () {
      return this.$refs.table
    }
  },
  watch: {
    // 通过修改样式控制表头初始化高度，这边不做监听
    // columns (val) {
    //   this.$nextTick(() => {
    //     this.$table && this.$table.doLayout()
    //   })
    // },
    // data () {
    //   this.$nextTick(() => {
    //     this.$table && this.$table.doLayout()
    //   })
    // }
  },
  methods: {
    toggleRowSelection (row, selected) {
      this.$table && this.$table.toggleRowSelection(row, selected)
    }
  }
}
</script>

<style lang="scss">
.base-table.el-table {
  width: 100%;
  th.is-leaf {
    background: #f5f7fa;
  }
  .el-button {
    padding: 3px;
  }
  .el-button--text {
    padding: 0;
  }
  th > .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    white-space: nowrap;
  }
}
</style>
