<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
.filter-container {
  .el-card__body {
    padding: 0;
  }
}
.card-action-box {
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
