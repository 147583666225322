import Cookies from 'js-cookie'

const TokenKey = 'loginToken'
const RefreshKey = 'refreshToken'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken () {
  return Cookies.get(RefreshKey)
}

export function setRefreshToken (token) {
  return Cookies.set(RefreshKey, token)
}

export function removeRefreshToken () {
  return Cookies.remove(RefreshKey)
}
