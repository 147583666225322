import dayjs from 'dayjs'
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  addRouters: state => state.permission.addRouters,
  routers: state => state.permission.routers,
  isExpires: state => {
    if (!state.user.expiresTime) {
      return false
    }
    // 提前五分钟去刷新token
    return dayjs().add(300, 'second').unix() < state.user.expiresTime
  }
}
export default getters
