import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token && !config.headers.Authorization) {
      config.headers.Authorization = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.method.toLocaleLowerCase() === 'get' && config.params) {
      const obj = config.params
      Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key])) {
          try {
            obj[key] = obj[key].join(',')
          } catch (error) {

          }
        }
      })
      config.params = obj
    }
    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    if (response.status !== 200) {
      Message({
        message: response.message,
        type: 'error',
        duration: 3 * 1000
      })

      // 401:未登录;
      if (response.status === 401) {
        MessageBox.confirm(
          '你已被登出，可以取消继续留在该页面，或者重新登录',
          '确定登出',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject(new Error('error'))
    } else {
      return response
    }
  },
  (error) => {
    const response = error.response
    Message({
      message: response.data.message,
      type: 'error',
      duration: 3 * 1000
    })
    // 用户无权限
    if (response.status === 401) {
      store.dispatch('LoginOut').then(() => {
        // 注销用户
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
      return
    }
    return Promise.reject(error)
  }
)

export default service
