import dayjs from 'dayjs'
import { login, refreshToken, logout } from '@/api/login'
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    expiresTime: '',
    avatar: require('@/assets/logo.jpg'),
    roles: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_EXPIRES: (state, expiresTime) => {
      state.expiresTime = expiresTime
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login(username, userInfo.password).then(response => {
          const data = response.data
          const tokenStr = `${data.token_type} ${data.access_token}`
          setToken(tokenStr)
          setRefreshToken(data.refresh_token)
          commit('SET_TOKEN', tokenStr)
          commit('SET_EXPIRES', dayjs().add(data.expires_in, 'second').unix())
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 刷新token
    RefreshToken ({ commit, dispatch }) {
      const token = getRefreshToken()
      return new Promise((resolve, reject) => {
        if (!token) {
          dispatch('LogOut')
          return
        }
        refreshToken(token).then(response => {
          const data = response.data
          const tokenStr = `${data.token_type} ${data.access_token}`
          setToken(tokenStr)
          setRefreshToken(data.refresh_token)
          commit('SET_TOKEN', tokenStr)
          commit('SET_EXPIRES', dayjs().add(data.expires_in, 'second').unix())
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    // GetInfo ({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo().then(response => {
    //       const data = response.data
    //       if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', data.roles)
    //       } else {
    //         reject(new Error('getInfo: roles must be a non-null array !'))
    //       }
    //       commit('SET_NAME', data.username)
    //       commit('SET_AVATAR', data.icon)
    //       resolve(response)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
          removeRefreshToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        removeRefreshToken()
        resolve()
      })
    }
  }
}

export default user
