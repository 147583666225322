
export default {
  data () {
    return {
      tableLoading: false
    }
  },
  props: {
    theme: String,
    size: {
      type: String,
      default: 'mini'
    },
    /**
     * 表格列配置
     */
    columns: {
      type: Array,
      default: () => []
    },
    /**
     * 是否带有纵向边框
     */
    border: {
      type: Boolean,
      default: true
    },
    // 是否多选
    isSelection: {
      type: Boolean,
      default: true
    },
    // 当前行是否可选
    selectable: {
      type: Function,
      default: (row) => {
        return true
      }
    },
    indexNumber: {
      type: Boolean,
      default: false
    },
    spanMethod: Function
  }
}
