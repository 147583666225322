import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '/chart/chartDate',
    name: 'chartDate',
    component: () => import('@/views/chart/date/index'),
    meta: { title: '门店营业额-日汇报', icon: 'el-icon-pie-chart' },
    hidden: true
  },
  {
    path: '/chart/chartOwnership',
    name: 'ownershipDate',
    component: () => import('@/views/chart/ownership/index'),
    meta: { title: '股权门店日汇报', icon: 'el-icon-pie-chart' },
    hidden: true
  },
  {
    path: '',
    component: () => import('@/views/layout/Layout'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'home' }
      }
    ]
  },
  {
    path: '/shop',
    component: () => import('@/views/layout/Layout'),
    redirect: '/shop/shopList',
    name: 'shop',
    meta: { title: '门店管理', icon: 'el-icon-s-shop' },
    children: [
      {
        path: 'shopList',
        name: 'shopList',
        component: () => import('@/views/shop/shop/index'),
        meta: { title: '门店列表', icon: 'el-icon-menu' }
      },
      {
        path: 'shopNote',
        name: 'shopNote',
        component: () => import('@/views/shop/note/index'),
        meta: { title: '短信订阅', icon: 'el-icon-message' }
      }
    ]
  },
  {
    path: '/record',
    component: () => import('@/views/layout/Layout'),
    redirect: '/record/date',
    name: 'record',
    meta: { title: '数据查询', icon: 'el-icon-s-data' },
    children: [
      {
        path: 'date',
        name: 'date',
        component: () => import('@/views/record/date/index'),
        meta: { title: '日营业额数据', icon: 'el-icon-s-data' }
      },
      {
        path: 'mounth',
        name: 'mounth',
        component: () => import('@/views/record/month/index'),
        meta: { title: '月营业额数据', icon: 'el-icon-s-data' }
      }
    ]
  },
  {
    path: '/chart',
    component: () => import('@/views/layout/Layout'),
    redirect: '/chart/date',
    name: 'chart',
    meta: { title: '营业额', icon: 'el-icon-pie-chart' },
    children: [
      {
        path: 'date',
        name: 'chartDate2',
        component: () => import('@/views/chart/date/index'),
        meta: { title: '门店日汇报', icon: 'el-icon-pie-chart' }
      },

      {
        path: 'ownership',
        name: 'chartDate2',
        component: () => import('@/views/chart/ownership/index'),
        meta: { title: '股权门店日汇报', icon: 'el-icon-pie-chart' }
      }
    ]
  },
  {
    path: '/account',
    component: () => import('@/views/layout/Layout'),
    redirect: '/account/list',
    name: 'account',
    meta: { title: '账号管理', icon: 'el-icon-user' },
    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('@/views/account/list/index'),
        meta: { title: '用户列表', icon: 'el-icon-user' }
      }
    ]
  },
  {
    path: '/log',
    component: () => import('@/views/layout/Layout'),
    redirect: '/log/editData',
    name: 'log',
    meta: { title: '日志管理', icon: 'el-icon-tickets' },
    children: [
      {
        path: 'editData',
        name: 'editData',
        component: () => import('@/views/log/edit-data/index'),
        meta: { title: '日志管理', icon: 'el-icon-tickets' }
      }
    ]
  }
]

const asyncRouterMap = []

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export { asyncRouterMap, constantRouterMap }
