import BasePaging from './base-paging'
import BaseTable from './base-table'
import BaseDialog from './base-dialog'
import BaseForm from './base-form'
import BaseFormItem from './base-form-item'

const components = [BasePaging, BaseTable, BaseDialog, BaseForm, BaseFormItem]

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  install
}
