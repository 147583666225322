<template>
  <div :style="style">
    <slot name="prepend" />
    <el-form-item ref="item" v-bind="$attrs">
      <slot></slot>
    </el-form-item>
    <slot name="append" />
  </div>
</template>
<script>
export default {
  name: 'base-form-item',
  inject: ['cols'],
  props: {
    /**
     * 列数
     * @type {Object}
     */
    col: {
      type: [Number, String],
      default: 1
    },
    /**
     * 是否行内布局，启用则不会使用列宽计算
     * @type {Object}
     */
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cols () {
      return this.cols || 2
    },
    style () {
      if (this.inline) return {}

      const col = this.col || 1
      const cols = parseInt(this.cols)
      const style = {
        width: col / cols * 100 + '%'
      }
      return style
    },
    clearValidate () {
      return this.$refs.item.clearValidate
    }
  }
}
</script>
