import server from '@/utils/server'

export function login (username, password, type = 'password') {
  return server({
    url: '/oauth/token',
    method: 'post',
    params: {
      grant_type: type,
      username,
      password
    },
    headers: {
      Authorization: `Basic ${window.btoa('backend:123456')}`
    }
  })
}

export function refreshToken (refreshToken) {
  return server({
    url: '/oauth/token',
    method: 'post',
    params: {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    },
    headers: {
      Authorization: `Basic ${window.btoa('backend:123456')}`
    }
  })
}

export function logout () {
  return server({
    url: '/users/logout',
    method: 'post'
  })
}
