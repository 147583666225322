<template>
  <div :class="$options.name">
    <div class="prepend">
      <!-- @slot 分页栏前部 -->
      <slot name="prepend" />
    </div>

    <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="paging.pageIndex"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="paging.pageSize"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <!-- @slot 分页栏尾部 -->
    <slot name="append" />
  </div>
</template>

<script>
export default {
  name: 'base-paging',
  props: {
    paging: {
      type: Object,
      default: () => ({
        pageSize: 20,
        pageIndex: 1,
        totalPage: null,
        totalCount: null
      })
    }
  },
  computed: {
    total () {
      return this.paging.totalCount || this.paging.totalRecord || 0
    }
  },
  methods: {
    handleSizeChange (pageSize) {
      this.paging.pageIndex = 1
      this.$emit('sizeChange', { pageSize, pageIndex: 1 })
    },
    handleCurrentChange (pageIndex) {
      this.$emit('indexChange', { pageIndex })
    }
  }
}
</script>

<style lang="scss">
.base-paging {
  display: flex;
  align-items: center;
  padding: 0 12px;
  min-height: 48px;
  .prepend {
    flex: 1;
  }
}
</style>
