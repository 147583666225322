<template>
  <el-dialog
    :class="$options.name"
    :visible.sync="visible"
    :fullscreen="fullscreen"
    :close-on-click-modal="false"
    :append-to-body="true"
    v-bind="_elDialogProps"
    ref="dialog"
    v-on="elDialogEvents">
    <div slot="title" @mousedown="handleMove" :class="{'dialog-drag': isDrag}">
      <span class="title">{{title}}</span>
      <div class="head-icon">
        <i v-if="isMax" class="el-icon-full-screen icon" @click="handleFullsceen"></i>
      </div>
    </div>
    <div class="dialog-container" :style="{height: _height + 'px'}">
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
/* begin ---- 重写el的clickoutside，以触发拖动时能关闭poper框 */
/* 这是个很蛋疼的需求和代码注入 */
import ELEMENT from 'element-ui'
import { on } from 'element-ui/src/utils/dom'
const nodeList = []
const ctx = '@@clickoutsideContext'

let startClick
let seed = 0

// 预留后门
if (window.$ep_form_hack_clickoutside !== false && ELEMENT) {
  const Clickoutside = ELEMENT.Select.directives.Clickoutside

  on(document, 'mousedown', e => (startClick = e))
  on(document, 'mouseup', e => {
    if (!startClick) return
    nodeList.forEach(node => node[ctx] && node[ctx].documentHandler(e, startClick))
  })

  const createDocumentHandler = function createDocumentHandler (el, binding, vnode) {
    return function (mouseup = {}, mousedown = {}) {
      if (!vnode ||
        !vnode.context ||
        !mouseup.target ||
        !mousedown.target ||
        el.contains(mouseup.target) ||
        el.contains(mousedown.target) ||
        el === mouseup.target ||
        (vnode.context.popperElm &&
        (vnode.context.popperElm.contains(mouseup.target) ||
        vnode.context.popperElm.contains(mousedown.target)))) return

      if (binding.expression &&
        el[ctx].methodName &&
        vnode.context[el[ctx].methodName]) {
        vnode.context[el[ctx].methodName]()
      } else {
        el[ctx].bindingFn && el[ctx].bindingFn()
      }
    }
  }
  Clickoutside.bind = function _bind (el, binding, vnode) {
    nodeList.push(el)
    const id = seed++
    el[ctx] = {
      id,
      documentHandler: createDocumentHandler(el, binding, vnode),
      methodName: binding.expression,
      bindingFn: binding.value
    }
  }
  Clickoutside.update = function _update (el, binding, vnode) {
    el[ctx].documentHandler = createDocumentHandler(el, binding, vnode)
    el[ctx].methodName = binding.expression
    el[ctx].bindingFn = binding.value
  }
  Clickoutside.unbind = function _unbind (el) {
    const len = nodeList.length

    for (let i = 0; i < len; i++) {
      if (nodeList[i][ctx].id === el[ctx].id) {
        nodeList.splice(i, 1)
        break
      }
    }
    delete el[ctx]
  }
}
/* end ---- 以上代码关联弹窗拖拽方法 onmousemove */

// EF: ep-form
export default {
  name: 'base-dialog',
  props: {
    /**
     * 弹框标题
     * @type {String}
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * 是否可全屏
     * @type {Boolean}
     */
    isMax: {
      type: Boolean,
      default: true
    },
    /**
     * 是否可拖动
     * @type {Boolean}
     */
    isDrag: {
      type: Boolean,
      default: true
    },
    /**
     * 是否默认全屏
     * @type {Boolean}
     */
    isFullscreen: {
      type: Boolean,
      default: false
    },
    /**
     * 默认宽度
     * @type {String}
     */
    width: {
      type: String,
      default: '800px'
    },
    /**
     * 默认高度
     * @type {String}
     */
    height: {
      type: String,
      default: '480px'
    },
    /**
     * margin-top 值
     * @type {String}
     */
    top: {
      type: String,
      default: '15vh'
    },
    /**
     * 弹框配置项
     * @type {Object}
     */
    elDialogProps: {
      type: Object,
      default: () => {}
    },
    /**
     * 弹框事件配置项
     * @type {Object}
     */
    elDialogEvents: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      visible: true,
      fullscreen: null
    }
  },
  computed: {
    _height () {
      return parseInt(this.height) - 50
    },
    _elDialogProps () {
      return Object.assign({
        width: this.width
      }, this.elDialogProps)
    },
    $dialog () {
      return this.$el.querySelector('.el-dialog')
    }
  },
  watch: {
    fullscreen (val) {
      this.$nextTick(() => {
        const $dialog = this.$dialog
        if (val === true) {
          // 全屏：100vh
          $dialog.style.top = '0'
          $dialog.style.left = '50%'
          /**
           * 全屏
           */
          this.$emit('fullscreen')
        } else {
          // 非全屏：100vh - top - 5vh(底部预留)
          const top = this.top
          $dialog.style.top = top
          $dialog.style.maxHeight = `calc(95vh - ${top})`
          $dialog.querySelector('.dialog-container').style.maxHeight = `calc(95vh - ${top} - 50px)`
          /**
           * 取消全屏
           */
          this.$emit('nofullscreen')
        }
      })
    },
    visible (val) {
      if (!val) {
        /**
         * 关闭弹窗
         */
        this.$emit('close')
      }
    }
  },
  mounted () {
    // 暂时不做响应式配置
    this.fullscreen = this.isFullscreen
  },
  methods: {
    // 全屏
    handleFullsceen () {
      this.fullscreen = !this.fullscreen
      this.$emit('full-screen', this.fullscreen)
    },
    // 拖拽移动位置
    handleMove (e) {
      // 获取目标元素
      if (this.fullscreen) return
      if (!this.isDrag) return
      const $dialog = this.$dialog
      const {
        clientHeight,
        clientWidth,
        halfWidth,
        targetHeight,
        distanceX,
        distanceY,
        distanceHeight,
        distanceRight
      } = this.getElementCss(e, $dialog)
      // 鼠标移动事件
      document.onmousemove = e => {
        // *** 污染处理 - 拖拽触发clickoutside
        if (startClick) {
          nodeList.forEach(node => node[ctx] && node[ctx].documentHandler(e, startClick))
          startClick = null
          // 对于非标准clickoutside实现的发送约定广播
          this.$root.$emit('control:close-popover')
        }
        let left = e.clientX - distanceX
        let top = e.clientY - distanceY
        // 到最左边
        if (left - halfWidth < 0) {
          left = halfWidth
        }
        // 到最顶上
        if (top < 0) {
          top = 0
        }
        // 最底部
        if (e.clientY + distanceHeight > clientHeight) {
          top = clientHeight - targetHeight
        }
        // 最右边
        if (e.clientX + distanceRight - halfWidth > clientWidth) {
          left = clientWidth - halfWidth
        }
        $dialog.style.top = top + 'px'
        $dialog.style.left = left + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
    getElementCss (e, element) {
      // 获取浏览器当前可视区宽度高度
      const clientHeight = document.body.clientHeight
      const clientWidth = document.body.clientWidth

      // 目标元素的宽度,halfWidth：由于元素绝对定位用了transform: translateX(-50%);
      const targetWidth = element.offsetWidth
      const halfWidth = targetWidth / 2
      // 目标元素的高度
      const targetHeight = element.clientHeight
      // 获取鼠标点位置与目标元素的x，y的坐标距离，相较于目标元素的左上角
      const distanceX = e.clientX - element.offsetLeft
      const distanceY = e.clientY - element.offsetTop
      // 获取鼠标到目标元素底部的距离
      const distanceHeight = targetHeight - distanceY
      // 获取鼠标到目标元素右边的距离
      const distanceRight = targetWidth - distanceX
      return {
        clientHeight,
        clientWidth,
        halfWidth,
        targetHeight,
        distanceX,
        distanceY,
        distanceHeight,
        distanceRight
      }
    }
  }
}
</script>

<style lang="scss">
.base-dialog .el-dialog {
  margin: 0;
  margin-top: 0!important;
  left: 50%;
  transform: translateX(-50%);
  .el-dialog__body{
    padding: 0;
  }
  .dialog-container {
    padding: 0;
    box-sizing: border-box;
    overflow: auto;
  }
  .el-dialog__header {
    padding: 0 20px;
    height: 50px;
    .dialog-drag{
      cursor: move;
    }
    .title{
      line-height: 50px;
      user-select: none;
    }
    .el-dialog__headerbtn {
      top: 17px;
    }
  }
  .head-icon {
    position: absolute;
    right: 44px;
    top: 17px;
    line-height: 1.15;
    .icon {
      font-size: 16px;
      cursor: pointer;
      color: #ffffff;
    }
  }
}
.base-dialog .el-dialog.is-fullscreen {
  max-height: 100vh !important;
  .el-dialog__body {
    max-height: calc(100vh - 50px) !important;
    height: 100% !important;
  }
  .dialog-container {
    max-height: calc(100vh - 50px) !important;
    height: 100% !important;
  }
}
</style>
